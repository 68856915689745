<template>
  <div v-if="document" class="document-card">
    <div class="document-card__body">
      <div v-if="document.published_at || document.document_type" class="document-card__header">
        <span v-if="document.published_at" class="document-card__date">
          {{ document.published_at | formatDate }}
        </span>
        <span v-if="document.document_type" class="document-card__category">
          {{ document.document_type.title }}
        </span>
      </div>
      <p v-if="document.title" class="document-card__title">{{ document.title }}</p>
    </div>
    <router-link
      v-if="!activity"
      :to="{ name: 'document', params: { id: document.id } }"
      class="document-card__btn"
    >
      <span>Прочитать</span>
      <IconComponent name="arrow-right" />
    </router-link>
    <a
      v-else
      :href="media_endpoint + document?.file?.path"
      :download="document?.file?.origin_name"
      class="document-card__btn"
      target="_blank"
    >
      <span>Скачать</span>
      <IconComponent name="download" />
    </a>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "DocumentCardComponent",
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    activity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    media_endpoint() {
      return this.$store.state._env.MEDIA_ENDPOINT;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.document-card {
  display flex
  flex-direction column
  justify-content space-between
  gap 15px
  padding 30px
  border 1px solid var(--gray-dark)
  background url("/static/svg/coat-of-arms.svg")
  background-color var(--white)
  background-position 95% 20%
  background-repeat no-repeat

  &__body {
    display flex
    flex-direction column
    gap 15px
  }

  &__header {
    display flex
    align-items center
    gap 35px
  }

  &__date {
    font-size .875rem
    font-weight 700
    line-height 22px
    color var(--main)
  }

  &__category {
    font-size .875rem
    line-height 22px
    color var(--main_o5)
  }

  &__btn {
    display flex
    align-items center
    gap 14px

    span {
      font-size .875rem
      line-height 22px
    }

    .icon svg {
      max-width 16px
      max-height 16px
    }
  }
}
</style>
